import { About } from "@/types";
import { BentoGrid, BentoGridItem } from "./ui/bento-grid";

interface GridProps {
    items: About[];
}

export const Grid = ({ items }: GridProps) => {
    return (
        <section id="about" className="min-h-screen">
            <h2 className="heading">
                Ein wenig <span className="text-orange-300">über mich</span>
            </h2>
            <BentoGrid>
                {items.map(
                    ({
                        id,
                        title,
                        description,
                        className,
                        imgClassName,
                        titleClassName,
                        img,
                        spareImg,
                    }) => (
                        <BentoGridItem
                            key={"grid-item-" + id}
                            id={id ? Number(id) : 0}
                            title={title}
                            description={description}
                            className={className ?? ""}
                            img={img ?? ""}
                            imgClassName={imgClassName ?? ""}
                            titleClassName={titleClassName ?? ""}
                            spareImg={spareImg ?? ""}
                        />
                    )
                )}
            </BentoGrid>
        </section>
    );
};
