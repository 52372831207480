import { FaLocationArrow } from "react-icons/fa6";
import { MagicButton } from "../ui/magic-button";
import { socialMedia } from "@/data";

export const Contact = () => {
    return (
        <section id="contact" className="relative w-full min-h-96 pt-10 pb-10">
            <div className="min-h-96 w-full">
                <div
                    className="absolute left-0 bottom-20 w-full h-full opacity-50"
                    style={{
                        backgroundImage: "url(/footer-grid.svg)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                    }}
                />
                <div className="flex flex-col items-center">
                    <h2 className="heading lg:max-w-[45vw]">
                        Sie möchten{" "}
                        <span className="text-orange-300">Ihren</span>{" "}
                        Internetauftritt modernisieren?
                    </h2>
                    <p className="text-white-200 md:mt-10 my-5 text-center">
                        Kontaktieren Sie mich gerne
                    </p>
                    <a href="mailto:martin@martin-richter.dev">
                        <MagicButton
                            title="In Kontakt treten"
                            icon={<FaLocationArrow />}
                            position="right"
                        />
                    </a>
                </div>
            </div>
            <footer className="flex flex-col md:flex-row mt-16 justify-between items-center ">
                <p className="md:text-base text-sm md:font-normal font-light">
                    Copyright © 2024{" "}
                    <span className="text-orange-300">Martin Richter</span>
                </p>
                <div className="flex items-center gap-6 md:gap-3">
                    {socialMedia.map((profile) => (
                        <a
                            key={profile.id}
                            href={profile.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className="text-white flex w-10 h-10 justify-center items-center backdrop-filter backdrop-blur-lg saturate-150 bg-opacity-75 bg-black-200 rounded-lg border border-black-300">
                                <img
                                    src={profile.img}
                                    alt={profile.name}
                                    className="w-6 h-6"
                                />
                            </div>
                        </a>
                    ))}
                </div>
            </footer>
        </section>
    );
};
