import { Button } from "./button";

interface MagicButtonProps {
    title: string;
    icon?: React.ReactNode;
    position?: string;
    className?: string;
    handleClick?: () => void;
}

export const MagicButton = ({
    className,
    title,
    icon,
    position,
    handleClick,
}: MagicButtonProps) => {
    return (
        <Button
            type="button"
            onClick={handleClick}
            className={className}
            variant="magic"
            size="magic"
        >
            <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
            <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-slate-950 px-7 text-sm font-medium text-white backdrop-blur-3xl gap-2">
                {position === "left" && icon}
                {title}
                {position === "right" && icon}
            </span>
        </Button>
    );
};
