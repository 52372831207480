import { FaLocationArrow } from "react-icons/fa6";
import { MagicButton } from "./ui/magic-button";
import { Spotlight } from "./ui/spotlight";
import { TextGenerateEffect } from "./ui/text-generate-effect";
import { router } from "@inertiajs/react";

export const Hero = () => {
    const handleClick = () => {
        router.visit("/#projects");
    };
    return (
        <section className="pb-20 pt-36 min-h-screen">
            <div>
                <Spotlight
                    className="-top-72 -left-28 md:-left-[40rem] md:-top-80 max-h-screen"
                    fill="orange"
                />
                <Spotlight
                    className="-top-20 left-72 max-h-[80vh] md:-top-36 md:-left-60"
                    fill="blue"
                />
            </div>
            <div className="h-screen w-full dark:bg-black-100 bg-white  dark:bg-grid-white/[0.03] bg-grid-black/[0.3] flex items-center justify-center absolute left-0 top-0">
                {/* Radial gradient for the container to give a faded look */}
                <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black-100 bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]" />
            </div>

            <div className="flex justify-center relative my-20 z-10">
                <div className="flex flex-col items-center justify-center max-w-[89vw] md:max-w-2xl lg:max-w-[60vw]">
                    <TextGenerateEffect
                        className="max-w-[460px] md:max-w-full md:w-[672px] lg:w-[665px] text-center text-4xl md:text-5xl lg:text-6xl font-bold text-white"
                        words="Web Development mit Laravel & React"
                    />
                    <p>
                        Hi, mein Name ist Martin, ich bin ein Fullstack
                        Developer
                    </p>
                    <MagicButton
                        className="mt-8"
                        title="Aktuelle Projekte"
                        icon={<FaLocationArrow />}
                        position="right"
                        handleClick={handleClick}
                    />
                </div>
            </div>
        </section>
    );
};
