import { motion } from "framer-motion";

import { sampleArcs, gridGlobeConfig } from "../data/globeConfig";

import { World } from "./ui/globe";

export const GridGlobe = () => {
    return (
        <div className="absolute -left-5 top-36 md:top-40 flex items-center justify-center h-full w-full">
            <div className="max-w-7xl mx-auto w-full relative overflow-hidden px-4 h-96">
                <div className="absolute w-full bottom-0 inset-x-0 h-40 bg-gradient-to-b pointer-events-none select-none from-transparent dark:to-black to-white z-40" />
                <div className="absolute w-full h-72 md:h-full z-10">
                    <World data={sampleArcs} globeConfig={gridGlobeConfig} />
                </div>
            </div>
        </div>
    );
};
