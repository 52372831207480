import { cn } from "@/lib/utils";
import { BackgroundGradientAnimation } from "./background-gradient-animation";
import { GridGlobe } from "../grid-globe";
import Lottie from "react-lottie";
import { useState } from "react";

import { FaLaravel, FaReact } from "react-icons/fa6";
import { IoCopyOutline } from "react-icons/io5";
import { SiTypescript, SiTailwindcss, SiPhp, SiMysql } from "react-icons/si";

import animationData from "@/data/confetti.json";
import { MagicButton } from "./magic-button";
import { TechStack } from "../tech-stack";
import { TechStackItemType } from "../tech-stack/item";

export const BentoGrid = ({
    className,
    children,
}: {
    className?: string;
    children?: React.ReactNode;
}) => {
    return (
        <div
            className={cn(
                "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 max-w-7xl mx-auto ",
                className
            )}
        >
            {children}
        </div>
    );
};

interface BentoGridItemProps {
    className?: string;
    title?: string | React.ReactNode;
    description?: string | React.ReactNode;
    img?: string;
    imgClassName?: string;
    titleClassName?: string;
    spareImg?: string;
    header?: React.ReactNode;
    icon?: React.ReactNode;
    id?: number;
}

export const BentoGridItem = ({
    className,
    title,
    description,
    img,
    imgClassName,
    titleClassName,
    spareImg,
    header,
    icon,
    id,
}: BentoGridItemProps) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        const email = "martin@martin-richter.dev";
        navigator.clipboard.writeText(email);
        setCopied(true);
    };

    const techStackItems: TechStackItemType[] = [
        { name: "PHP", icon: <SiPhp /> },
        { name: "MySql", icon: <SiMysql /> },
        { name: "Laravel", icon: <FaLaravel /> },
        { name: "Typescript", icon: <SiTypescript /> },
        { name: "React", icon: <FaReact /> },
        { name: "Tailwind CSS", icon: <SiTailwindcss /> },
    ];

    return (
        <div
            className={cn(
                "row-span-1 relative rounded-3xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none justify-between flex flex-col border border-white/[0.1]",
                className
            )}
            style={{
                background: "rgb(4,7,29)",
                backgroundColor:
                    "linear-gradient(111deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
            }}
        >
            {header}
            <div className={`${id === 5 && "flex justify-center"} h-full'`}>
                <div
                    className={cn("w-full h-full absolute", imgClassName)}
                    style={{
                        backgroundImage: `url(${img})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
            </div>
            <div
                className={`absolute right-0 -bottom-5 ${
                    id === 5 && "w-full opacity-80"
                }`}
            >
                {spareImg && (
                    <img
                        src={spareImg}
                        alt={spareImg}
                        className="object-center object-cover w-full h-full"
                    />
                )}
            </div>
            {id === 5 && (
                <BackgroundGradientAnimation>
                    {/* <div className="absolute z-50 flex items-center justify-center text-white font-bold"></div> */}
                </BackgroundGradientAnimation>
            )}

            <div
                className={cn(
                    "relative min-h-40 gap-2 flex flex-col py-5 lg:py-6 md:h-full group-hover/bento:translate-x-1 transition duration-200",
                    titleClassName
                )}
            >
                {icon}

                <div className="font-sans font-extralight text-[#c1c2d3] max-w-[30rem] text-sm px-5 lg:px-10">
                    {description}
                </div>
                <div className="font-sans font-bold text-lg lg:text-3xl max-w-[30rem] z-10 px-5 lg:px-10">
                    {title}
                </div>

                {id === 1 && <TechStack items={techStackItems} />}
                {id === 2 && <GridGlobe />}
                {id === 5 && (
                    <div className="relative mt-5">
                        <div className={`absolute -bottom-5 right-0`}>
                            <Lottie
                                options={{
                                    loop: copied,
                                    autoplay: copied,
                                    animationData,
                                    rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                    },
                                }}
                            />
                        </div>
                        <MagicButton
                            title={
                                copied
                                    ? "E-Mail kopiert"
                                    : "E-Mail Adresse kopieren"
                            }
                            icon={<IoCopyOutline />}
                            handleClick={handleCopy}
                            position="left"
                            className="!bg-[#161a31]"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
