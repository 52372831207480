export type TechStackItemType = {
    name: string;
    icon: React.ReactNode;
};

interface TechStackItemProps {
    item: TechStackItemType;
}

export const TechStackItem = ({ item }: TechStackItemProps) => {
    return (
        <div className="flex gap-3 items-center py-2 px-3 lg:py-4 text-xs text-center lg:text-base opacity-50 lg:opacity-100 rounded-lg bg-[#10132e]">
            {item.icon}
            <span>{item.name}</span>
        </div>
    );
};
