import { Project } from "@/types";
import { PinContainer } from "./ui/3d-pin";
import { FaLocationArrow } from "react-icons/fa6";

interface ProjectDisplayProps {
    project: Project;
}

export const ProjectDisplay = ({ project }: ProjectDisplayProps) => {
    const { id, title, des, img, link, iconList } = project;
    const iconListArray = iconList ? JSON.parse(iconList) : [];
    return (
        <div
            key={"project-" + id}
            className="flex items-center justify-center h-[32rem] w-[90vw] sm:w-[570px] sm:h-[41rem] lg:min-h-[32.5rem]"
        >
            <PinContainer
                title={link ? link : undefined}
                href={link ? link : undefined}
            >
                <div className="relative flex items-center justify-center w-[80vw] h-[30vh] sm:h-[40vh] sm:w-[570px] overflow-hidden mb-10">
                    <div className="relative w-full h-full overflow-hidden lg:rounded-3xl bg-[#13162d]">
                        <img src="/bg.png" alt="bg-img" />
                    </div>
                    <img
                        src={img ? img : "/bg.png"}
                        alt={title}
                        className="absolute bottom-0 z-10"
                    />
                </div>
                <h1 className="font-bold text-base md:text-xl lg:text-2xl line-clamp-1">
                    {title}
                </h1>
                <p className="text-sm font-light lg:text-xl lg:font-normal line-clamp-2">
                    {des}
                </p>

                <div className="flex items-center justify-between mt-7 mb-3">
                    <div className="flex items-center">
                        {iconListArray.map((icon: string, index: number) => (
                            <div
                                key={icon}
                                className="flex items-center justify-center border border-white/[0.2] rounded-full bg-black size-8 lg:size-10"
                                style={{
                                    transform: `translateX(-${
                                        5 * index * 2
                                    }px)`,
                                }}
                            >
                                <img src={icon} alt={icon} className="p-2" />
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center items-center">
                        <p className="text-sm md:text-xs lg:text-xl text-orange-300">
                            Webseite besuchen
                        </p>
                        <FaLocationArrow className="ms-3 text-orange-300" />
                    </div>
                </div>
            </PinContainer>
        </div>
    );
};
