import { TechStackItem, TechStackItemType } from "./item";

interface TechStackProps {
    items: TechStackItemType[];
}

export const TechStack = ({ items }: TechStackProps) => {
    const splitIndex = Math.floor(items.length / 2);
    const lineOneItems = items.slice(0, splitIndex);
    const lineTwoItems = items.slice(splitIndex);

    return (
        <div className="text-white lg:-right-2 flex flex-col gap-1 lg:gap-5 w-fit self-end mt-8">
            <div className="flex gap-3 lg:gap-8">
                {lineOneItems.map((item, index) => (
                    <TechStackItem item={item} key={index} />
                ))}
            </div>
            <div className="flex gap-3 lg:gap-8">
                {lineTwoItems.map((item, index) => (
                    <TechStackItem item={item} key={index} />
                ))}
            </div>
        </div>
    );
};
