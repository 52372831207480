import { Contact } from "@/components/contact";
import { Grid } from "@/components/grid";
import { Hero } from "@/components/Hero";
import { RecentProjects } from "@/components/recent-projects";

import { FloatingNav } from "@/components/ui/floating-navbar";
import { navItems } from "@/data";
import { About, PageProps, Project } from "@/types";

export type IndexProps = { projects: Project[]; about: About[] } & PageProps;

const Index = ({ auth, projects, about }: IndexProps) => {
    return (
        <main className="relative bg-black-100 flex justify-center items-center flex-col overflow-hidden mx-auto sm:px-10 px-5">
            <div className="max-w-7xl w-full">
                <FloatingNav navItems={navItems} />
                <Hero />
                <Grid items={about} />
                <RecentProjects projects={projects} />
                <Contact />
            </div>
        </main>
    );
};

export default Index;
