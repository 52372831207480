import { Project } from "@/types";
import { PinContainer } from "./ui/3d-pin";
import { FaLocationArrow } from "react-icons/fa6";
import { ProjectDisplay } from "./project-display";

interface RecentProjectsProps {
    projects: Project[];
}

export const RecentProjects = ({ projects }: RecentProjectsProps) => {
    return (
        <section id="projects" className="py-20 min-h-screen">
            <h2 className="heading">
                Eine Auswahl meiner{" "}
                <span className="text-orange-300">neuesten Projekte</span>
            </h2>
            <div className="flex flex-wrap items-center justify-center p-4 gap-x-24 gap-y-8 mt-10">
                {projects.map((project) => (
                    <ProjectDisplay project={project} />
                ))}
            </div>
        </section>
    );
};
